import { IAdditionalInterestInfo } from '@coverforce-platform/cf-common-api-model';
import { AdditionalInterestType } from '@coverforce-platform/cf-common-types';

import { IAdditionalInterestStore } from './interface';

export const ADDITIONAL_INTEREST_STORE_META_DATA = {
  AUTOFILL_ADDITIONAL_INTEREST: 'AUTOFILL_ADDITIONAL_INTEREST',
  ADDITIONAL_INTEREST_ERROR: 'ADDITIONAL_INTEREST_ERROR',
  UPDATE_ADDITIONAL_INTEREST_STORE_BY_KEY: 'UPDATE_ADDITIONAL_INTEREST_STORE_BY_KEY',
  CLEAR_ADDITIONAL_INTEREST_STORE: 'CLEAR_ADDITIONAL_INTEREST_STORE',
  ANONYMOUS_ACTION_NAME: 'ADDITIONAL_INTEREST_ANONYMOUS_ACTION',
  STORE_NAME: 'ADDITIONAL_INTEREST_STORE',
};

export const ADDITIONAL_INTEREST_STORE_KEYS: { [key: string]: keyof IAdditionalInterestStore } = {
  ADDITIONAL_INTEREST_LOADING: 'additionalInterestLoading',
  ADDITIONAL_INTEREST_LIST: 'additionalInterestList',
};

export const OPTIONAL_NAME_ADDITIONAL_INTEREST_TYPES: AdditionalInterestType[] = [
  AdditionalInterestType.EXECUTORS_ADMINISTRATORS_TRUSTEES_BENEFICIARIES,
  AdditionalInterestType.OWNERS_LESSEES_CONTRACTORS_WRITTEN_AGREEMENT,
  AdditionalInterestType.PRIMARY_AND_NON_CONTRIBUTORY,
];

export const ADDITIONAL_INTEREST_QUESTION_KEYS: {
  NAME: keyof IAdditionalInterestInfo;
  TYPE: keyof IAdditionalInterestInfo;
} = {
  NAME: 'additionalInterestName',
  TYPE: 'additionalInterestType',
};

export const ADDITIONAL_INTEREST_KEYS_MAPPING = {
  [ADDITIONAL_INTEREST_QUESTION_KEYS.NAME]: 'additional interest full name.',
  [ADDITIONAL_INTEREST_QUESTION_KEYS.TYPE]: 'additional interest type.',
};
