import { FileButton } from '../../ui-core/FileButton';
import { LabelStyled } from './styles';

export const listOfSteps = [
  {
    labelStyled: 'Step 1: ',
    description: <>Download the below AmTrust Marketing Application</>,
    body: (
      <FileButton
        id='btn_am_trust_marketing_doc'
        file={{
          name: 'AmTrust Marketing Application',
          url: '/AmTrustMarketingApplication.doc',
        }}
        isDownloadable
      />
    ),
  },
  {
    labelStyled: 'Step 2: ',
    description: (
      <>
        Fill out the <LabelStyled>General Information</LabelStyled> section. The other sections are helpful, but not
        mandatory.
      </>
    ),
    body: (
      <>
        <li>
          Answer <LabelStyled>'No'</LabelStyled> to “Is the entity a Master Agency for an Aggregator, Cluster or
          Network?”
        </li>
        <li>
          Answer <LabelStyled>'Yes'</LabelStyled> to “Is the entity acting as a sub-agency for Aggregator, Cluster or
          Network?”
        </li>
        <li>
          Name of Master Agency: <LabelStyled>ISU Network</LabelStyled>
        </li>
      </>
    ),
  },
  {
    labelStyled: 'Step 3: ',
    description: <>Email the Marketing Application form to Jason.Lucas@amtrustgroup.com </>,
    body: (
      <>
        <li>
          Subject: <LabelStyled>AMTRUST - [Insert Agency Name] appointment</LabelStyled>
        </li>
        <li>Copy in: carrieraccess@coverforce.com to help us track the application</li>
      </>
    ),
  },
  {
    labelStyled: 'Step 4: ',
    description: (
      <>
        You will receive an email in 24-72 hours from AmTrust (producerexpress@sircon.com) with a link to upload your
        agency documents (W-9, Proof of Insurance, etc.){' '}
      </>
    ),
    body: (
      <>
        <li>Keep an eye on your Spam folder</li>
      </>
    ),
  },
  {
    labelStyled: 'Step 5: ',
    description: (
      <>Sit back. It can take as little as a few hours (to a few days) for AmTrust to finalize your appointment."</>
    ),
    body: <></>,
  },
];
