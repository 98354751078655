import { listOfSteps } from './constants';
import { IAmtrustAppointmentProps } from './interface';
import {
  CFDrawerStyled,
  DescriptionStyled,
  LabelStyled,
  StepDescriptionStyled,
  StepHeaderStyled,
  StepsListStyled,
  StepStyled,
} from './styles';

export const AmtrustAppointment = ({ width = 378 }: IAmtrustAppointmentProps) => {
  return (
    <CFDrawerStyled
      id='drawer_amtrust_appointment'
      width={width > window.innerWidth ? window.innerWidth : width}
      title='AmTrust Appointment'
    >
      <StepsListStyled>
        {listOfSteps.map((item) => (
          <StepStyled key={item.labelStyled}>
            <StepHeaderStyled>
              <LabelStyled>{item.labelStyled}</LabelStyled>
              <DescriptionStyled>{item.description}</DescriptionStyled>
            </StepHeaderStyled>
            <StepDescriptionStyled>{item.body}</StepDescriptionStyled>
          </StepStyled>
        ))}
      </StepsListStyled>
    </CFDrawerStyled>
  );
};
