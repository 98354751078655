import { Drawer } from 'antd';
import styled from 'styled-components/macro';

import CFButton from '../../ui-core/V2/cfButton/cfButton';
import CFMenu from '../../ui-core/V2/cfMenu/cfMenu';

export const MenuDrawerStyled = styled(Drawer)`
  .ant-drawer-body {
    background-color: ${(p) => p?.theme?.colorScheme?.primary};
    color: ${(p) => p.theme.colors.grayscale.white};
    height: calc(100vh - 55px);
    padding: 0;
  }

  .ant-drawer-header {
    background-color: ${(p) => p?.theme?.colorScheme?.primary};
    color: ${(p) => p.theme.colors.grayscale.white} !important;
    text-align: center;
    border-bottom: 0px solid;
  }

  .ant-drawer-title {
    color: ${(p) => p.theme.colors.grayscale.white} !important;
  }
`;

export const LogoWrapperStyled = styled.div`
  margin-right: 30px;
  padding: 10px 0px;
  height: 100%;
  max-height: 64px;
  max-width: 200px;

  @media (max-width: 1135px) {
    margin: auto;
  }
`;

export const LogoStyled = styled.img<{ themeName?: string }>`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const NavItemStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;

  @media (max-width: 1135px) {
    width: 100%;
  }

  .ant-btn {
    align-self: center;
  }
  // .ant-btn {
  //   align-self: center;
  //   background-color: ${(p) => p.theme?.colorScheme?.primary};
  //   border: 0.5px solid ${(p) => p.theme.colors.grayscale.white};
  //   margin-right: 30px;

  //   // To Verify for mobile view
  //   // .menuBtn {
  //   //   border: 1px solid ${(p) => p.theme.colors.grayscale.white} !important;
  //   //   position: absolute !important;
  //   //   background-color: ${(p) => p?.theme?.colorScheme?.primary} !important;
  //   // }

  //   // .mobileNav {
  //   //   display: contents !important;
  //   // }

  //   // @media (max-width: 912px) {
  //   //   margin-right: 0px;
  //   // }

  //   :hover {
  //     background-color: ${(p) => p.theme?.colorScheme?.primaryHover};
  //   }
  // }
`;

export const CFNavMenuStyled = styled(CFMenu)`
  // .ant-dropdown-menu-item,
  // .ant-dropdown-menu-submenu-title {
  //   padding: 12px 25px;
  // }

  // .ant-dropdown-menu-item-icon {
  //   font-size: 18px;
  //   color: ${(p) => p.theme.colors.grayscale.darkGray};
  // }

  // .ant-dropdown-menu-item.ant-dropdown-menu-item-danger .ant-dropdown-menu-item-icon {
  //   color: inherit;
  // }

  // .ant-dropdown-menu-item-selected,
  // .ant-dropdown-menu-submenu-title-selected {
  //   color: ${(p) => p.theme.colors.navBar.dropDownItemSelectedTextColor};
  //   background-color: ${(p) => p.theme.colors.navBar.dropDownItemSelectedBgColor};
  //   font-weight: ${(p) => p.theme.typography.fontWeight.bold};

  //   .anticon {
  //     color: ${(p) => p.theme.colors.navBar.dropDownItemSelectedTextColor};
  //   }
  // }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 12px 25px !important;
  }

  .ant-dropdown-menu-item-icon {
    font-size: 18px !important;
    color: ${(p) => p.theme.colors.grayscale.darkGray} !important;
  }

  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger .ant-dropdown-menu-item-icon {
    color: inherit !important;
  }

  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected {
    color: ${(p) => p.theme.colors.navBar.dropDownItemSelectedTextColor} !important;
    background-color: ${(p) => p.theme.colors.navBar.dropDownItemSelectedBgColor} !important;
    font-weight: ${(p) => p.theme.typography.fontWeight.bold} !important;

    .anticon {
      color: ${(p) => p.theme.colors.navBar.dropDownItemSelectedTextColor} !important;
    }
  }
`;

export const CFButtonStyled = styled(CFButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 138px;
  border-radius: 24px;
  margin-right: 20px;
  background-color: ${(p) => p.theme?.colorScheme?.primary};
  border: 0.5px solid ${(p) => p.theme.colors.grayscale.white};
  :hover {
    background-color: ${(p) => p.theme?.colorScheme?.primaryHover};
  }

  @media only screen and (min-width: 1020px) and (max-width: 1350px) {
    height: 40px;
    width: 100px;
    font-size: ${(p) => p.theme.typography.fontSize.mini};
    margin-right: 10px;
  }
`;
