export const REACT_APP_API_BASE_URL = '#REACT_APP_API_BASE_URL#';
export const REACT_APP_AUDIENCE_AUTH0 = '#REACT_APP_AUDIENCE_AUTH0#';
export const REACT_APP_SCOPES_AUTH0 = '#REACT_APP_SCOPES_AUTH0#';
export const REACT_APP_CLIENT_ID_AUTH0 = '#REACT_APP_CLIENT_ID_AUTH0#';
export const REACT_APP_DOMAIN_AUTH0 = '#REACT_APP_DOMAIN_AUTH0#';
export const REACT_APP_API_VERSION = '#REACT_APP_API_VERSION#';
export const REACT_APP_APPLICATION_SERVICE_BASE_URL = '#REACT_APP_APPLICATION_SERVICE_BASE_URL#';
export const REACT_APP_APPLICATION_HELPER_SERVICE_BASE_URL = '#REACT_APP_APPLICATION_HELPER_SERVICE_BASE_URL#';
export const REACT_APP_PREMIUM_FINANCE_SERVICE_BASE_URL = '#REACT_APP_PREMIUM_FINANCE_SERVICE_BASE_URL#';
export const REACT_APP_APPLICATION_LIFECYCLE_SERVICE_BASE_URL = '#REACT_APP_APPLICATION_LIFECYCLE_SERVICE_BASE_URL#';
export const REACT_APP_NOTIFICATIONS_SERVICE_BASE_URL = '#REACT_APP_NOTIFICATIONS_SERVICE_BASE_URL#';
export const REACT_APP_EMAIL_JS_PUBLIC_KEY = '#REACT_APP_EMAIL_JS_PUBLIC_KEY#';
export const REACT_APP_EMAIL_JS_SERVICE_ID = '#REACT_APP_EMAIL_JS_SERVICE_ID#';
export const REACT_APP_ENV = '#REACT_APP_ENV#';
export const GROWTHBOOK_API_KEY = '#REACT_GROWTHBOOK_ENV_APP_ID#';
export const REACT_APP_ONLINE_STORE_URL = '#REACT_APP_ONLINE_STORE_URL#';
export const REACT_S3_BUCKET_URL = '#REACT_S3_BUCKET_URL#';
export const REACT_APP_GA_KEY = '#REACT_APP_GA_KEY#';
export const KNOCK_API_KEY = '#KNOCK_API_KEY#';
export const KNOCK_FEED_ID = '#KNOCK_FEED_ID#';
export const KNOCK_FCM_CHANNEL_ID = '#KNOCK_FCM_CHANNEL_ID#';
export const FIREBASE_CONFIG_API_KEY = '#FIREBASE_CONFIG_API_KEY#';
export const FIREBASE_CONFIG_AUTH_DOMAIN = '#FIREBASE_CONFIG_AUTH_DOMAIN#';
export const FIREBASE_CONFIG_PROJECT_ID = '#FIREBASE_CONFIG_PROJECT_ID#';
export const FIREBASE_CONFIG_MESSAGING_SENDER_ID = '#FIREBASE_CONFIG_MESSAGING_SENDER_ID#';
export const FIREBASE_CONFIG_APP_ID = '#FIREBASE_CONFIG_APP_ID#';
export const FIREBASE_VAPID_PUBLIC_KEY = '#FIREBASE_VAPID_PUBLIC_KEY#';

// ---------------------------------------------------- DEV START ---------------------------------------------------

// export const REACT_APP_API_BASE_URL = 'https://accounts-dev.api.coverforce.com/api';
// export const REACT_APP_APPLICATION_SERVICE_BASE_URL = 'https://applications-dev.api.coverforce.com/api';
// export const REACT_APP_APPLICATION_HELPER_SERVICE_BASE_URL = 'https://application-helper-dev.api.coverforce.com/api';
// export const REACT_APP_PREMIUM_FINANCE_SERVICE_BASE_URL = 'https://premium-finance-dev.api.coverforce.com/api';
// export const REACT_APP_APPLICATION_LIFECYCLE_SERVICE_BASE_URL =
//   'https://application-lifecycle-dev.api.coverforce.com/api';
// export const REACT_APP_NOTIFICATIONS_SERVICE_BASE_URL = 'https://notifications-dev.api.coverforce.com/api';
// export const REACT_APP_AUDIENCE_AUTH0 = 'com.cf';
// export const REACT_APP_SCOPES_AUTH0 = '';
// export const REACT_APP_CLIENT_ID_AUTH0 = 'ftIE3ZmcQMWUmDyfyNQ69evLY4P1ONFz';
// export const REACT_APP_DOMAIN_AUTH0 = 'auth.webapp-dev.coverforce.com';
// export const REACT_APP_API_VERSION = 'v1';
// export const REACT_APP_EMAIL_JS_PUBLIC_KEY = '3YXzWtL3cndLDszzs';
// export const REACT_APP_EMAIL_JS_SERVICE_ID = 'service_lrwu8o8';
// export const REACT_APP_ENV = 'dev';
// export const GROWTHBOOK_API_KEY = 'key_dev_9889a6c518dd1bbd';
// export const REACT_APP_ONLINE_STORE_URL = 'https://cf-online-dev.coverforce.com/';
// export const REACT_S3_BUCKET_URL = 'https://cf-static-assets-dev.api.coverforce.com';
// export const REACT_APP_GA_KEY = 'G-VP5WVV7Z5W';
// export const KNOCK_API_KEY = 'pk_test_GZxuABKNtJOyu8Ak-99CDgTG3Fqx1SnSyI_Yz53C49g';
// export const KNOCK_FEED_ID = 'b7940160-d1f1-4c5d-a670-b39e0a5c2b2d';
// export const KNOCK_FCM_CHANNEL_ID = '1b4770a2-3687-4847-8e53-a46ceaa60212';
// export const FIREBASE_CONFIG_API_KEY = 'AIzaSyAte7hgrrHwODCwEqOnzqu4NNQgng7yCqA';
// export const FIREBASE_CONFIG_AUTH_DOMAIN = 'cf-notifications-dev.firebaseapp.com';
// export const FIREBASE_CONFIG_PROJECT_ID = 'cf-notifications-dev';
// export const FIREBASE_CONFIG_MESSAGING_SENDER_ID = '16295922169';
// export const FIREBASE_CONFIG_APP_ID = '1:16295922169:web:f597ab5043688dd711d528';
// export const FIREBASE_VAPID_PUBLIC_KEY =
//   'BFu0qf_3BPYpHDawavE_JuuUSVcBfPqNGhuXr4W6LIg0kNVUKeYQO85V64M-4HpQTpHJ9aKwfrQlUoEpktG16s8';

// ---------------------------------------------------- DEV END ------------------------------------------------------

// ---------------------------------------------------- STAGING START ---------------------------------------------------

// export const REACT_APP_API_BASE_URL = 'https://accounts-staging.api.coverforce.com/api';
// export const REACT_APP_APPLICATION_SERVICE_BASE_URL = 'https://applications-staging.api.coverforce.com/api';
// export const REACT_APP_APPLICATION_HELPER_SERVICE_BASE_URL =
//   'https://application-helper-staging.api.coverforce.com/api';
// export const REACT_APP_PREMIUM_FINANCE_SERVICE_BASE_URL = 'https://premium-finance-staging.api.coverforce.com/api';
// export const REACT_APP_APPLICATION_LIFECYCLE_SERVICE_BASE_URL =
//   'https://application-lifecycle-staging.api.coverforce.com/api';
// export const REACT_APP_NOTIFICATIONS_SERVICE_BASE_URL = 'https://notifications-staging.api.coverforce.com/api';
// export const REACT_APP_AUDIENCE_AUTH0 = 'com.cf';
// export const REACT_APP_SCOPES_AUTH0 = '';
// export const REACT_APP_CLIENT_ID_AUTH0 = 'ftIE3ZmcQMWUmDyfyNQ69evLY4P1ONFz';
// export const REACT_APP_DOMAIN_AUTH0 = 'auth.webapp-dev.coverforce.com';
// export const REACT_APP_API_VERSION = 'v1';
// export const REACT_APP_EMAIL_JS_PUBLIC_KEY = '3YXzWtL3cndLDszzs';
// export const REACT_APP_EMAIL_JS_SERVICE_ID = 'service_lrwu8o8';
// export const REACT_APP_ENV = 'staging';
// export const GROWTHBOOK_API_KEY = 'key_stag_e6469b5852e20c8a';
// export const REACT_APP_ONLINE_STORE_URL = 'https://cf-online-staging.coverforce.com/';
// export const REACT_S3_BUCKET_URL = 'https://cf-static-assets-dev.api.coverforce.com';
// export const REACT_APP_GA_KEY = 'G-28ZJ2ZJYD1';
// export const KNOCK_API_KEY = 'pk_test_GZxuABKNtJOyu8Ak-99CDgTG3Fqx1SnSyI_Yz53C49g';
// export const KNOCK_FEED_ID = 'b7940160-d1f1-4c5d-a670-b39e0a5c2b2d';
// export const KNOCK_FCM_CHANNEL_ID = '1b4770a2-3687-4847-8e53-a46ceaa60212';
// export const FIREBASE_CONFIG_API_KEY = 'AIzaSyAte7hgrrHwODCwEqOnzqu4NNQgng7yCqA';
// export const FIREBASE_CONFIG_AUTH_DOMAIN = 'cf-notifications-dev.firebaseapp.com';
// export const FIREBASE_CONFIG_PROJECT_ID = 'cf-notifications-dev';
// export const FIREBASE_CONFIG_MESSAGING_SENDER_ID = '16295922169';
// export const FIREBASE_CONFIG_APP_ID = '1:16295922169:web:f597ab5043688dd711d528';
// export const FIREBASE_VAPID_PUBLIC_KEY =
//   'BFu0qf_3BPYpHDawavE_JuuUSVcBfPqNGhuXr4W6LIg0kNVUKeYQO85V64M-4HpQTpHJ9aKwfrQlUoEpktG16s8';

// ---------------------------------------------------- STAGING END ------------------------------------------------------

// ---------------------------------------------------- SNP START ---------------------------------------------------

// export const REACT_APP_API_BASE_URL = 'https://accounts-snp.api.coverforce.com/api';
// export const REACT_APP_APPLICATION_SERVICE_BASE_URL = 'https://applications-snp.api.coverforce.com/api';
// export const REACT_APP_APPLICATION_HELPER_SERVICE_BASE_URL = 'https://application-helper-snp.api.coverforce.com/api';
// export const REACT_APP_PREMIUM_FINANCE_SERVICE_BASE_URL = 'https://premium-finance-snp.api.coverforce.com/api';
// export const REACT_APP_APPLICATION_LIFECYCLE_SERVICE_BASE_URL =
//   'https://application-lifecycle-snp.api.coverforce.com/api';
// export const REACT_APP_NOTIFICATIONS_SERVICE_BASE_URL = 'https://notifications-snp.api.coverforce.com/api';
// export const REACT_APP_AUDIENCE_AUTH0 = 'com.cf';
// export const REACT_APP_SCOPES_AUTH0 = '';
// export const REACT_APP_CLIENT_ID_AUTH0 = 'YwYnG4LTRTclBLAueMeKYMyGlr1w82Xy';
// export const REACT_APP_DOMAIN_AUTH0 = 'auth.webapp-snp.coverforce.com';
// export const REACT_APP_API_VERSION = 'v1';
// export const REACT_APP_EMAIL_JS_PUBLIC_KEY = '3YXzWtL3cndLDszzs';
// export const REACT_APP_EMAIL_JS_SERVICE_ID = 'service_lrwu8o8';
// export const REACT_APP_ENV = 'snp';
// export const GROWTHBOOK_API_KEY = 'key_snp_db4ead24c3cd0ba4';
// export const REACT_APP_ONLINE_STORE_URL = 'https://cf-online-snp.coverforce.com/';
// export const REACT_S3_BUCKET_URL = 'https://cf-static-assets-snp.api.coverforce.com';
// export const REACT_APP_GA_KEY = 'G-6WDZ1NTBCN';
// export const KNOCK_API_KEY = 'pk_F2WQCTZp_jvPPFNGGq4vgwZmdS9oBpkrCfswv6HN_XM';
// export const KNOCK_FEED_ID = 'b7940160-d1f1-4c5d-a670-b39e0a5c2b2d';
// export const KNOCK_FCM_CHANNEL_ID = '1b4770a2-3687-4847-8e53-a46ceaa60212';
// export const FIREBASE_CONFIG_API_KEY = 'AIzaSyBEJ-cTwWm3k7Gyl5wnESc-smULxhX9Dp0';
// export const FIREBASE_CONFIG_AUTH_DOMAIN = 'cf-notifications-snp.firebaseapp.com';
// export const FIREBASE_CONFIG_PROJECT_ID = 'cf-notifications-snp';
// export const FIREBASE_CONFIG_MESSAGING_SENDER_ID = '774094787027';
// export const FIREBASE_CONFIG_APP_ID = '1:774094787027:web:472d377a12f369f200df26';
// export const FIREBASE_VAPID_PUBLIC_KEY =
//   'BPy3Bc_MiLjoWauOo9GDr59th_thbI7RLe5zYj3yxKjOpeE4GF-ztwCujJ0m16w-0Q3i9Eeynp4r9P8lSSQd8yY';

// ---------------------------------------------------- SNP END ------------------------------------------------------
