import { QUERY_PARAMS } from '../../../globalConstants';
import { PAYMENT_GATEWAY_PROFILE_ID_QUERY_PARAM_REPLACEMENT } from './constants';

export const generateReturnURI = (returnURI: string, paymentTxnReferenceURIQueryParams: string) => {
  // If there are no query params to append from payment gateway, return the original returnURI
  if (!paymentTxnReferenceURIQueryParams) {
    return returnURI;
  }

  // if profileId is present in gateway URL then rename it to pg_profileId, as profileId is being used in coverforce system as well.
  paymentTxnReferenceURIQueryParams = paymentTxnReferenceURIQueryParams.replace(
    QUERY_PARAMS.PROFILE_ID,
    PAYMENT_GATEWAY_PROFILE_ID_QUERY_PARAM_REPLACEMENT,
  );

  if (returnURI.includes('?')) {
    // If the returnURI already has query params, append the payment gateway query params with '&'
    return `${returnURI}&${paymentTxnReferenceURIQueryParams?.split('?')[1]}`;
  } else {
    // If the returnURI does not have query params, append the payment gateway query params with '?'
    return `${returnURI}?${paymentTxnReferenceURIQueryParams}`;
  }
};
