import { IAdditionalInterestInfo } from '@coverforce-platform/cf-common-api-model';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { cloneDeep } from '../../utils/lodash';
import { ADDITIONAL_INTEREST_STORE_KEYS } from '../additionalInterest/constants';
import { useAdditionalInterestStore } from '../additionalInterest/store';
import { ADDITIONAL_INTEREST_MODAL_STORE_META_DATA } from './constants';
import { IAdditionalInterestModalStore } from './interface';

export const useAdditionalInterestModalStore = create<IAdditionalInterestModalStore>()(
  devtools(
    (set, get) => ({
      additionalInterestModalForm: undefined,
      additionalInterestInfoIndex: -1,

      addOrUpdateAdditionalInterestDetails: async () => {
        const { additionalInterestInfoIndex, additionalInterestModalForm } = get();
        const { additionalInterestList, updateAdditionalInterestStoreByKey } = useAdditionalInterestStore.getState();
        const additionalInterestListCloned = cloneDeep(additionalInterestList);

        if (additionalInterestInfoIndex === -1) {
          const additionalInterestInfo: IAdditionalInterestInfo = additionalInterestModalForm?.getFieldsValue();
          additionalInterestListCloned.push(additionalInterestInfo);
        } else {
          const existingAdditionalInterestInfoCloned = cloneDeep(additionalInterestList[additionalInterestInfoIndex]);
          const updatedAdditionalInterestInfo: IAdditionalInterestInfo =
            additionalInterestModalForm?.getFieldsValue(true);
          additionalInterestListCloned[additionalInterestInfoIndex] = {
            ...existingAdditionalInterestInfoCloned,
            ...updatedAdditionalInterestInfo,
          };
        }
        updateAdditionalInterestStoreByKey(
          ADDITIONAL_INTEREST_STORE_KEYS.ADDITIONAL_INTEREST_LIST,
          additionalInterestListCloned,
        );
      },

      validateAdditionalInterestModalDetails: async () => {
        const { additionalInterestModalForm } = get();
        try {
          await additionalInterestModalForm?.validateFields();
          return true;
        } catch (error) {
          return false;
        }
      },

      updateAdditionalInterestModalStoreByKey: async (storeKey: keyof IAdditionalInterestModalStore, value: any) => {
        set(
          () => ({ [storeKey]: value }),
          false,
          ADDITIONAL_INTEREST_MODAL_STORE_META_DATA.UPDATE_ADDITIONAL_INTEREST_MODAL_STORE_BY_KEY,
        );
      },

      clearAdditionalInterestModal: () => {
        set(
          { additionalInterestModalForm: undefined, additionalInterestInfoIndex: -1 },
          false,
          ADDITIONAL_INTEREST_MODAL_STORE_META_DATA.CLEAR_ADDITIONAL_INTEREST_MODAL_STORE,
        );
      },
    }),

    {
      anonymousActionType: ADDITIONAL_INTEREST_MODAL_STORE_META_DATA.ANONYMOUS_ACTION_NAME,
      name: ADDITIONAL_INTEREST_MODAL_STORE_META_DATA.STORE_NAME,
    },
  ),
);
