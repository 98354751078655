import { AdditionalInterestType } from '@coverforce-platform/cf-common-types';

import { ADDITIONAL_INTEREST_TYPE_DISPLAY_TEXT } from '../../constants/additionalInterest';
import { OPTIONAL_NAME_ADDITIONAL_INTEREST_TYPES } from '../additionalInterest/constants';

export const getAdditionalInterestOptions = () => {
  return (Object.values(AdditionalInterestType) || [])
    .filter((item) => ADDITIONAL_INTEREST_TYPE_DISPLAY_TEXT[item])
    .map((item) => {
      return { label: ADDITIONAL_INTEREST_TYPE_DISPLAY_TEXT[item], value: item };
    });
};

export const getAdditionalInterestNameTooltip = () => {
  const interestTypes = OPTIONAL_NAME_ADDITIONAL_INTEREST_TYPES.map(
    (item) => `'${ADDITIONAL_INTEREST_TYPE_DISPLAY_TEXT[item]}'`,
  );

  const formattedInterestTypes = `Name is optional for ${
    interestTypes.length > 1
      ? `${interestTypes.slice(0, -1).join(', ')} and ${interestTypes[interestTypes.length - 1]}`
      : interestTypes[0]
  } Additional Interest types`;
  return formattedInterestTypes;
};
