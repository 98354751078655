import { CalendarOutlined, CommentOutlined, HomeOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';

import { helpCenterLink, POPUP_TYPE } from '../../../globalConstants';
import Anchor from '../../../ui-core/V2/anchor/anchor';
import { ANCHOR_TARGET } from '../../../ui-core/V2/anchor/constants';
import { CFMenuItem } from '../../../ui-core/V2/cfMenu/cfMenu';
import { useCFModalStore } from '../../../ui-core/V2/cfModal/store';
import { useConfigurationStore } from '../../configuration/store';
import { useScheduleDemoStore } from '../../modals/scheduleDemo/store';
import { CFNavMenuStyled } from '../../navBarV2/styles';
import { IHelpMenuProps } from './interface';

const HelpMenu = ({ onClickContactUs }: IHelpMenuProps) => {
  const theme: any = useTheme();
  const { userTheme } = useConfigurationStore();
  const { setScheduleDemoLink } = useScheduleDemoStore();
  const { setCfModalData } = useCFModalStore();

  const handleScheduleDemoMenuItemClick = () => {
    if (userTheme?.links?.demo) {
      setScheduleDemoLink(userTheme.links.demo);
      setCfModalData({ isVisible: true, popupType: POPUP_TYPE.SCHEDULE_DEMO_MODAL });
    }
  };

  return (
    <CFNavMenuStyled id='nav_menu_help' backgroundColor={theme.colors.grayscale.white}>
      <CFMenuItem key='help_center' id='help_center' icon={<HomeOutlined />}>
        <Anchor id='anchor_help_center' href={helpCenterLink} target={ANCHOR_TARGET.BLANK}>
          Help Center
        </Anchor>
      </CFMenuItem>
      <CFMenuItem key='submit_request' id='submit_request' icon={<CommentOutlined />} onClick={onClickContactUs}>
        Submit a request
      </CFMenuItem>
      {userTheme?.links?.demo && (
        <CFMenuItem
          key='schedule_demo'
          id='schedule_demo'
          icon={<CalendarOutlined />}
          onClick={handleScheduleDemoMenuItemClick}
        >
          Schedule Demo
        </CFMenuItem>
      )}
    </CFNavMenuStyled>
  );
};

export default HelpMenu;
