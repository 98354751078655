import { useEffect, useState } from 'react';

import { useApplicationStore } from '../../pages/v2/application/store';
import CFForm, { useForm, useWatch } from '../../ui-core/V2/cfForm/cfForm';
import CFFormItem from '../../ui-core/V2/cfFormItem/cfFormItem';
import CFModal from '../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { cloneDeep } from '../../utils/lodash';
import {
  ADDITIONAL_INTEREST_QUESTION_KEYS,
  OPTIONAL_NAME_ADDITIONAL_INTEREST_TYPES,
} from '../additionalInterest/constants';
import { useAdditionalInterestStore } from '../additionalInterest/store';
import ApplicationQuestion from '../applicationQuestion/applicationQuestion';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import { getAdditionalInterestQuestions } from './componentUtils';
import { ADDITIONAL_INTEREST_MODAL_STORE_KEYS } from './constants';
import { useAdditionalInterestModalStore } from './store';

const AdditionalInterestModal = () => {
  const [form] = useForm();
  const { clearCfModal } = useCFModalStore();
  const { isReadOnlyApplication } = useApplicationStore();
  const { additionalInterestList } = useAdditionalInterestStore();
  const {
    additionalInterestInfoIndex,
    addOrUpdateAdditionalInterestDetails,
    updateAdditionalInterestModalStoreByKey,
    validateAdditionalInterestModalDetails,
    clearAdditionalInterestModal,
  } = useAdditionalInterestModalStore();

  const [additionalInterestQuestions, setAdditionalInterestQuestions] = useState<IApplicationQuestion[]>([]);
  const additionalInterestTypeValue = useWatch([ADDITIONAL_INTEREST_QUESTION_KEYS.TYPE], form);

  useEffect(() => {
    updateAdditionalInterestModalStoreByKey(ADDITIONAL_INTEREST_MODAL_STORE_KEYS.ADDITIONAL_INTEREST_MODAL_FORM, form);

    return () => {
      clearAdditionalInterestModal();
      clearCfModal();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (additionalInterestInfoIndex !== -1) {
      form?.setFieldsValue(cloneDeep(additionalInterestList[additionalInterestInfoIndex]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (form || additionalInterestTypeValue) {
      const updatedAdditionalInterestQuestions = getAdditionalInterestQuestions();
      setAdditionalInterestQuestions(updatedAdditionalInterestQuestions);
    }
  }, [form, additionalInterestTypeValue]);

  const handleAdditionalInterestFormChange = (value: any, data: any) => {
    switch (data?.dataIndex) {
      case ADDITIONAL_INTEREST_QUESTION_KEYS.TYPE: {
        if (value && OPTIONAL_NAME_ADDITIONAL_INTEREST_TYPES.includes(value)) {
          form?.setFields([
            {
              name: [ADDITIONAL_INTEREST_QUESTION_KEYS.NAME],
              value: undefined,
              errors: [],
            },
          ]);
        }
        break;
      }
      default:
        break;
    }
  };

  const handleAdditionalInterestModalSubmit = async () => {
    const isAdditionalInterestModalFormValid = await validateAdditionalInterestModalDetails();
    if (isAdditionalInterestModalFormValid) {
      addOrUpdateAdditionalInterestDetails();
      clearAdditionalInterestModal();
      clearCfModal();
    }
  };

  return (
    <CFModal
      title='Enter Additional Interest Details'
      okText={`${additionalInterestInfoIndex === -1 ? 'Add' : 'Update'} Additional Interest Details`}
      onOk={handleAdditionalInterestModalSubmit}
      okButtonProps={{
        id: 'btn_ok_additional_interest_details',
        disabled: isReadOnlyApplication,
      }}
      cancelButtonProps={{
        id: 'btn_cancel_additional_interest_details',
        disabled: isReadOnlyApplication,
      }}
      width={600}
    >
      <CFForm id='form_additional_interest_modal' labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} form={form}>
        {additionalInterestQuestions.map((question: IApplicationQuestion) => {
          return (
            <>
              {!question?.hidden && (
                <CFFormItem
                  shouldUpdate
                  noStyle
                  id={`form_item_id_question_${question.dataIndex}`}
                  key={`form-item-key-question-${question.dataIndex}`}
                >
                  {() => {
                    return (
                      <ApplicationQuestion
                        question={question}
                        key={`question-${question.dataIndex}`}
                        allQuestions={additionalInterestQuestions}
                        form={form}
                        disabled={question.disabled || isReadOnlyApplication}
                        onChange={handleAdditionalInterestFormChange}
                      />
                    );
                  }}
                </CFFormItem>
              )}
            </>
          );
        })}
      </CFForm>
    </CFModal>
  );
};

export default AdditionalInterestModal;
