import { useEffect } from 'react';

import { IFRAME_POST_MESSAGE } from './constants';

const PaymentReturnURI = () => {
  useEffect(() => {
    window.parent.parent.postMessage(
      { type: IFRAME_POST_MESSAGE, payload: window.location.href, timeStamp: Date.now() },
      '*',
    );
  }, []);
  return <></>;
};

export default PaymentReturnURI;
