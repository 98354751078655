import { FileTextOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components/macro';

const FileIconContainer = styled.div`
  background: ${(p) => p.theme.custom.fileContainer.bg};
  border-radius: 8px;
  height: 40px;
  width: 40px;
  color: ${(p) => p.theme.custom.fileContainer.main};
  font-size: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: background 0.3s;
`;

const FileButtonStyled = styled(Button)`
  height: 60px;
  display: flex;
  align-items: center;
  border-color: ${(p) => p.theme.custom.fileContainer.buttonBorder} !important;
  margin-bottom: 20px;

  :hover {
    border-color: ${(p) => p.theme.custom.fileContainer.hoverBorder};
    background-color: ${(p) => p.theme.custom.fileContainer.bg};
    color: ${(p) => p.theme.colors.grayscale.black};
    ${FileIconContainer} {
    }
  }

  :focus {
    border-color: ${(p) => p.theme.custom.fileContainer.hoverBorder};
    color: ${(p) => p.theme.colors.grayscale.black};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 21px;
  padding-top: 3px;
`;

const ButtonSubtitle = styled.div`
  color: ${(p) => p.theme.colors.primary.darkGray};
  font-size: ${(p) => p.theme.typography.fontSize.small};
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
`;

export const FileButton = ({
  id,
  file,
  isDownloadable = false,
  componentStyle,
  fileNameStyle,
}: {
  id: string;
  file: { name: string; url: string };
  isDownloadable?: boolean;
  componentStyle?: React.CSSProperties;
  fileNameStyle?: React.CSSProperties;
}) => {
  const fileIcon = (
    <FileIconContainer>
      <FileTextOutlined />
    </FileIconContainer>
  );
  return (
    <FileButtonStyled
      id={id}
      icon={fileIcon}
      href={file.url}
      target='_blank'
      rel='noopener noreferrer'
      download={isDownloadable}
      disabled={!isDownloadable}
      style={componentStyle}
    >
      <Column>
        <div style={fileNameStyle}>{file.name}</div>
        {isDownloadable && <ButtonSubtitle>Click To Download</ButtonSubtitle>}
      </Column>
    </FileButtonStyled>
  );
};
