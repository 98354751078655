import { IAdditionalInterestModalStore } from './interface';

export const ADDITIONAL_INTEREST_MODAL_STORE_META_DATA = {
  UPDATE_ADDITIONAL_INTEREST_MODAL_STORE_BY_KEY: 'UPDATE_ADDITIONAL_INTEREST_MODAL_STORE_BY_KEY',
  CLEAR_ADDITIONAL_INTEREST_MODAL_STORE: 'CLEAR_ADDITIONAL_INTEREST_MODAL_STORE',
  ANONYMOUS_ACTION_NAME: 'ADDITIONAL_INTEREST_MODAL_ANONYMOUS_ACTION',
  STORE_NAME: 'Additional Interest Modal',
};

export const ADDITIONAL_INTEREST_MODAL_STORE_KEYS: { [key: string]: keyof IAdditionalInterestModalStore } = {
  ADDITIONAL_INTEREST_MODAL_FORM: 'additionalInterestModalForm',
  ADDITIONAL_INTEREST_INFO_INDEX: 'additionalInterestInfoIndex',
};
