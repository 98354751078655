import { Carrier, PolicyType } from '@coverforce-platform/cf-common-types';

// Hardcoded carrier rankings for UW Questions
export const UWQuestionsRanking: { [key: string]: Carrier[] } = {
  [PolicyType.WC]: [
    Carrier.AMTRUST,
    Carrier.GAIG,
    Carrier.TRAVELERS,
    Carrier.CHUBB,
    Carrier.LIBERTYMUTUAL,
    Carrier.CNA,
    Carrier.EMPLOYERS,
    Carrier.ACCIDENTFUND,
    Carrier.COMPWEST,
  ],
  [PolicyType.BOP]: [
    Carrier.GAIG,
    Carrier.AMTRUST,
    Carrier.TRAVELERS,
    Carrier.CHUBB,
    Carrier.LIBERTYMUTUAL,
    Carrier.CNA,
    Carrier.NATIONWIDE,
    Carrier.MERCHANTS,
    Carrier.COTERIE,
  ],
  [PolicyType.CGL]: [Carrier.LIBERTYMUTUAL, Carrier.CHUBB, Carrier.NATIONWIDE, Carrier.COTERIE],
  [PolicyType.MPL]: [Carrier.HISCOX],
};

// eslint-disable-next-line no-shadow
export enum OwnerOfficerMessageType {
  QUOTE = 'QUOTE',
  BIND = 'BIND',
}
