import { DRAWER_TYPE } from '../../globalConstants';
import { useCFDrawerStore } from '../../ui-core/V2/cfDrawer/store';
import { AmtrustAppointment } from '../amtrustAppointmentDrawer/amtrustAppointmentDrawer';

const Drawers = () => {
  const { drawerType } = useCFDrawerStore();

  return <>{drawerType === DRAWER_TYPE.AMTRUST_APPOINTMENT && <AmtrustAppointment />}</>;
};
export default Drawers;
