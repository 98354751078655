import { Carrier } from '@coverforce-platform/cf-common-types';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PageLoaderStyled } from '../../../globalStyles';
import { errorV2 } from '../../../ui-core/Notification';
import CFImage from '../../../ui-core/V2/cfImage/cfImage';
import CFLoader from '../../../ui-core/V2/cfLoader/cfLoader';
import { PAYMENT_PUBLIC_REDIRECT_MESSAGES, PAYMENT_REDIRECT_STORE_KEYS } from './constants';
import { usePaymentRedirectStore } from './store';
import { ImageWrapperStyled, TextWrapperStyled, WrapperStyled } from './styles';
import { generateReturnURI } from './utils';

const PaymentPublicRedirect = () => {
  const location = useLocation();
  const { carrierId, applicationId, cfQuoteId } = useParams();

  const { paymentRedirectLoading, paymentRedirectError, getPaymentRedirectData, updatePaymentRedirectStoreByKey } =
    usePaymentRedirectStore();

  useEffect(() => {
    (async () => {
      if (carrierId) {
        try {
          const queryParamsObject = {} as {
            [key: string]: string;
          };

          const queryParams = new URLSearchParams(location.search);
          queryParams?.forEach((value, key) => {
            queryParamsObject[key] = value;
          });

          const paymentRedirectResponse = await getPaymentRedirectData({
            carrierId: carrierId as Carrier,
            txnReferenceDetails: queryParamsObject,
            quoteDetails: !(applicationId && cfQuoteId) ? undefined : { applicationId, cfQuoteId },
          });
          updatePaymentRedirectStoreByKey(PAYMENT_REDIRECT_STORE_KEYS.PAYMENT_REDIRECT_DATA, paymentRedirectResponse);

          if (paymentRedirectResponse?.paymentIntegrationDetails?.returnURI) {
            window.location.href = generateReturnURI(
              paymentRedirectResponse.paymentIntegrationDetails.returnURI,
              location.search,
            );
          } else {
            errorV2(PAYMENT_PUBLIC_REDIRECT_MESSAGES.ERROR);
          }
        } catch (error: any) {
          updatePaymentRedirectStoreByKey(PAYMENT_REDIRECT_STORE_KEYS.PAYMENT_REDIRECT_ERROR, error);
          errorV2(error?.[0]?.errorMessage || PAYMENT_PUBLIC_REDIRECT_MESSAGES.ERROR);
        } finally {
          updatePaymentRedirectStoreByKey(PAYMENT_REDIRECT_STORE_KEYS.PAYMENT_REDIRECT_LOADING, false);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierId]);

  /**
   * * The CF Web App Unauthorized Payment Route opens a WebPage with a loader UI, that does the following:
   * 1. Calls an unauthenticated CF API which accepts the carrierId & transcation reference details.
   * 2. This API then queries the transaction collection in DB and provides the returnURI configured on CF backend.
   * 3. This Web Page then redirects to the returnURI obtained above (which may point to either the CF WebApp agent portal quote payments screen or the API client Web App payment screen), with the query params received from the transaction gateway.
   */

  return (
    <PageLoaderStyled>
      {paymentRedirectLoading && <CFLoader />}
      {!paymentRedirectLoading && paymentRedirectError && (
        <>
          <WrapperStyled>
            <ImageWrapperStyled>
              <CFImage id='img_something_wrong' src={'/images/something_wrong.gif'} />
            </ImageWrapperStyled>
            <TextWrapperStyled>
              <p>Oops. Something went wrong! Please try again later.</p>
            </TextWrapperStyled>
          </WrapperStyled>
        </>
      )}
    </PageLoaderStyled>
  );
};

export default PaymentPublicRedirect;
