import { Drawer } from 'antd';
import styled from 'styled-components/macro';

export const CFDrawerStyled = styled(Drawer)`
  // .ant-drawer-title {
  //   order: 0;
  // }

  .ant-drawer-close {
    order: 1;
    // margin-right: 0px;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
`;
