import { InlineWidget } from 'react-calendly';

import CFModal from '../../../ui-core/V2/cfModal/cfModal';
import { useScheduleDemoStore } from './store';

export const ScheduleDemoModal = () => {
  const { scheduleDemoLink } = useScheduleDemoStore();

  return (
    <CFModal width={'80%'} footer={null} title='Schedule a Demo'>
      {scheduleDemoLink && <InlineWidget url={scheduleDemoLink} />}
    </CFModal>
  );
};
