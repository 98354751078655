import { Divider } from 'antd';
import styled from 'styled-components/macro';

import Accordion from './ui-core/V2/accordion/accordion';

export const PageLoaderStyled = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const QuoteCardLeftSectionStyled = styled.div`
  width: 50%;
`;

export const QuoteCardLabelValueWrapperStyled = styled.div`
  margin-left: 8%;
`;

export const QuoteCardLabelStyled = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.regular};
  opacity: 0.6;
  font-size: ${(p) => p.theme.typography.fontSize.medium};
`;

export const QuoteCardValueStyled = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.large};
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  color: ${(p) => p?.theme?.colorScheme?.primary};
`;

export const QuoteDetailAccordianStyled = styled(Accordion)`
  padding-bottom: 20px;

  .ant-collapse-item .ant-collapse-header {
    background: ${(p) => p.theme.colors.primary.bgMuted};
    border-radius: 8px !important;
    padding: 10px;

    .ant-collapse-header-text {
      font-size: ${(p) => p.theme.typography.fontSize.small};
      color: ${(p) => p?.theme?.colorScheme?.primary};
      font-weight: ${(p) => p.theme.typography.fontWeight.medium};
    }
  }
`;

export const QuoteDetailAccordianRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${(p) => p.theme.typography.fontSize.mini};
`;

export const QuoteDetailAccordianNameStyled = styled.div.attrs((p: any) => p)`
  text-align: left;
  white-space: pre-wrap;
  width: ${(p) => (p.fullWidth ? '100%' : '50%')};

  a {
    color: ${(p) => p?.theme?.colorScheme?.primary};
    &:hover {
      color: ${(p) => p?.theme?.colorScheme?.primaryHover};
    }

    ${(p) =>
      p.disabled
        ? `
  pointer-events: none;
  cursor: default;
  `
        : ''}
  }
`;

export const QuoteDetailAccordianValueStyled = styled.div`
  text-align: right;
  width: 50%;
`;

export const QuoteDetailAccordionRowDividerStyled = styled(Divider)`
  margin: 8px 0;
`;
