import styled from 'styled-components/macro';

import CFDrawer from '../../ui-core/V2/cfDrawer/cfDrawer';

export const CFDrawerStyled = styled(CFDrawer)`
  // .ant-input-prefix > .anticon {
  //   color: ${(p) => p?.theme?.colors?.grayscale?.black};
  //   padding-right: 5px;
  // }

  // .ant-drawer-close {
  //   order: 1;
  //   margin-right: 0px;
  // }

  // .ant-drawer-title {
  //   order: 0;
  // }
`;

export const StepsListStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const StepStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepHeaderStyled = styled.div`
  margin-bottom: 10px;
`;

export const StepDescriptionStyled = styled.div`
  margin-left: 15px;
  margin-bottom: 20px;
`;

export const LabelStyled = styled.span`
  font-weight: bold;
`;

export const DescriptionStyled = styled.span``;
