import { ILocationInfo } from '@coverforce-platform/cf-common-api-model';
import {
  BOPLossType,
  Carrier,
  CGLLossType,
  CyberLossType,
  PolicyType,
  USState,
  WCLossType,
} from '@coverforce-platform/cf-common-types';
import dayjs from 'dayjs';

import { USState as USStateLabel } from '../../globalConstants';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getSelectedCarriersFromApplication } from '../../pages/v2/application/utils';
import { optionType } from '../../types';
import { cloneDeep, uniqBy } from '../../utils/lodash';
import { QUESTION_KEYS } from '../pastPolicyLoss/constants';
import { LOSS_DESCRIPTION_QUESTION, POLICY_LOSS_QUESTIONS, STATE_QUESTION } from './constants';
import { IPolicyLossModalQuestion } from './interface';

// INFO: this won't be arrow because this is used in direct constant file, if we declare this as arrow then it will be thrown an error
// eslint-disable-next-line func-style
function getLossTypeOptionsInLabelValue(types: Record<string, string>) {
  const result = Object.keys(types).map((key) => ({
    label: key
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' '),
    value: types[key],
  }));
  return result;
}

const WC_LOSS_TYPE_OPTIONS = getLossTypeOptionsInLabelValue(WCLossType);

const BOP_LOSS_TYPE_OPTIONS = getLossTypeOptionsInLabelValue(BOPLossType);

const CGL_LOSS_TYPE_OPTIONS = getLossTypeOptionsInLabelValue(CGLLossType);

const CYBER_LOSS_TYPE_OPTIONS = getLossTypeOptionsInLabelValue(CyberLossType);

export const isLossDescriptionFieldDisplay = () => {
  // if state is CA or policy type is BOP then and then we need to display loss description field
  const { applicationData, policyType } = useApplicationStore.getState();

  return (
    applicationData?.locationDetails?.some((location) => location?.address?.state === USState.CA) ||
    [PolicyType.BOP, PolicyType.CGL, PolicyType.MPL, PolicyType.CYBER].includes(policyType!)
  );
};

export const isStateFieldDisplay = () => {
  // If there is multiple locations then we need to add policy loss according to state for that reason we need to display state dropdown.
  const { applicationData } = useApplicationStore.getState();

  const states: string[] = [...new Set(applicationData?.locationDetails?.map((location) => location?.address?.state))];

  return states.length > 1;
};

export const isLossTypeFieldDisplay = () => {
  const { policyType } = useApplicationStore.getState();
  return policyType !== PolicyType.BR;
};

export const isStepsTakenToAvoidFutureClaimsDisplay = () => {
  const { policyType, applicationData } = useApplicationStore.getState();
  const applicationSelectedCarriers = getSelectedCarriersFromApplication(applicationData);
  return policyType === PolicyType.BOP && applicationSelectedCarriers.includes(Carrier.HISCOX);
};

export const getPolicyLossQuestion = () => {
  const { policyType } = useApplicationStore.getState();
  const questions = [...POLICY_LOSS_QUESTIONS];
  const questionsClone = cloneDeep(questions);

  let lossTypeIndex = questionsClone.findIndex((question) => question.dataIndex === QUESTION_KEYS.LOSS_TYPE);

  if (lossTypeIndex >= 0 && !isLossTypeFieldDisplay()) {
    questionsClone.splice(lossTypeIndex, 1);
    lossTypeIndex = -1;
  }

  if (lossTypeIndex >= 0 && isLossDescriptionFieldDisplay()) {
    const lossDescriptionQuestion =
      policyType === PolicyType.CYBER ? { ...LOSS_DESCRIPTION_QUESTION, rules: [] } : LOSS_DESCRIPTION_QUESTION;
    questionsClone.splice(lossTypeIndex + 1, 0, lossDescriptionQuestion);
  }

  const claimStatusIndex = questionsClone.findIndex((question) => question.dataIndex === QUESTION_KEYS.CLAIM_STATUS);
  if (claimStatusIndex >= 0 && isStateFieldDisplay()) {
    questionsClone.splice(claimStatusIndex, 0, STATE_QUESTION);
  }

  const stepsTakenToAvoidFutureClaimsIndex = questionsClone.findIndex(
    (question) => question.dataIndex === QUESTION_KEYS.STEPS_TAKEN_TO_AVOID_FUTURE_CLAIMS,
  );
  if (stepsTakenToAvoidFutureClaimsIndex >= 0 && !isStepsTakenToAvoidFutureClaimsDisplay()) {
    questionsClone.splice(stepsTakenToAvoidFutureClaimsIndex, 1);
  }

  return questionsClone;
};

export const addDynamicValueInQuestion = ({
  question,
  getFieldValue,
}: {
  question: IPolicyLossModalQuestion;
  getFieldValue: Function;
}): IPolicyLossModalQuestion => {
  switch (question.dataIndex) {
    case QUESTION_KEYS.CLAIM_DATE: {
      const claimDate = getFieldValue(QUESTION_KEYS.CLAIM_DATE);
      const lossDate = getFieldValue(QUESTION_KEYS.LOSS_DATE);
      if (!claimDate) {
        question['defaultPickerValue'] = lossDate;
      }
      question['disabledDate'] = (date: dayjs.Dayjs) => {
        return date && ((lossDate && date.isBefore(lossDate)) || date > dayjs().local());
      };
      break;
    }

    default:
      break;
  }
  return question;
};

export const addLossTypeAndStateOptionsInQuestions = (questions: IPolicyLossModalQuestion[]) => {
  const { applicationData } = useApplicationStore.getState();

  const lossTypeIndex = questions.findIndex((el) => el.dataIndex === QUESTION_KEYS.LOSS_TYPE);
  const stateTypeIndex = questions.findIndex((el) => el.dataIndex === QUESTION_KEYS.STATE);
  const policyType = applicationData?.policyDetails?.length && applicationData.policyDetails[0].policyType;

  let options: {
    lossType?: optionType[];
    stateType?: optionType[];
  } = {};

  if (stateTypeIndex !== -1) {
    const statesOptions = applicationData?.locationDetails?.map((location: ILocationInfo) => ({
      label: USStateLabel[location?.address?.state],
      value: location?.address?.state,
    }));

    options.stateType = uniqBy(statesOptions, (state) => state.value);
  }

  switch (policyType) {
    case PolicyType.BOP:
      options = { ...options, lossType: BOP_LOSS_TYPE_OPTIONS };
      break;
    case PolicyType.WC:
      options = { ...options, lossType: WC_LOSS_TYPE_OPTIONS };
      break;
    case PolicyType.CGL:
      options = { ...options, lossType: CGL_LOSS_TYPE_OPTIONS };
      break;
    case PolicyType.CYBER:
      options = { ...options, lossType: CYBER_LOSS_TYPE_OPTIONS };
      break;

    default:
      options = { ...options, lossType: WC_LOSS_TYPE_OPTIONS };
  }
  questions[lossTypeIndex] = {
    ...questions[lossTypeIndex],
    options: options.lossType,
  };
  questions[stateTypeIndex] = {
    ...questions[stateTypeIndex],
    options: options.stateType,
  };

  return questions;
};
