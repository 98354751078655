import { FALSY_VALUES } from '../../globalConstants';
import { sortBy } from '../../utils/sortBy';
import {
  ADDITIONAL_INTEREST_QUESTION_KEYS,
  OPTIONAL_NAME_ADDITIONAL_INTEREST_TYPES,
} from '../additionalInterest/constants';
import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import { useAdditionalInterestModalStore } from './store';
import { getAdditionalInterestNameTooltip, getAdditionalInterestOptions } from './utils';

export const getAdditionalInterestQuestions = (): IApplicationQuestion[] => {
  const { additionalInterestModalForm } = useAdditionalInterestModalStore.getState();

  return [
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Additional Interest Type',
      dataIndex: ADDITIONAL_INTEREST_QUESTION_KEYS.TYPE,
      placeholder: 'Select additional interest type',
      rules: [{ required: true, message: 'Please select additional interest type.' }],
      options: sortBy({ arr: getAdditionalInterestOptions(), sortKey: 'label' }),
      filterOption: (input: string, option: any) =>
        (option?.label as unknown as string)?.toLowerCase().includes(input?.toLowerCase()),
      getPopupContainer: () => document.body,
    },
    {
      type: ANTD_QUESTION_TYPE.STRING,
      name: 'Full Name',
      dataIndex: ADDITIONAL_INTEREST_QUESTION_KEYS.NAME,
      placeholder: 'Additional interest name',
      rules: [
        {
          required: true,
          validator: (_: any, value: any) => {
            if (FALSY_VALUES?.includes(value?.trim())) {
              return Promise.reject(new Error('Please enter additional interest full name.'));
            }
            return Promise.resolve();
          },
        },
      ],
      hidden: ((): boolean => {
        const interestType = additionalInterestModalForm?.getFieldValue(ADDITIONAL_INTEREST_QUESTION_KEYS.TYPE)?.trim();
        if (interestType && !OPTIONAL_NAME_ADDITIONAL_INTEREST_TYPES.includes(interestType)) {
          return false;
        }
        return true;
      })(),
      tooltip: getAdditionalInterestNameTooltip(),
    },
  ];
};
