import { useRoutes } from 'react-router-dom';

import PaymentPublicRedirect from './pages/v2/paymentPublicRedirect/paymentPublicRedirect';
import PaymentReturnURI from './pages/v2/paymentReturnURI/paymentReturnURI';

export const getPublicRoutes = () => [
  {
    path: '/public/payment-redirect/:carrierId',
    element: <PaymentPublicRedirect />,
  },
  {
    path: '/public/payment-redirect/:carrierId/:applicationId/:cfQuoteId/',
    element: <PaymentPublicRedirect />,
  },
  {
    path: '/public/payment/return-url',
    element: <PaymentReturnURI />,
  },
];

const PublicAppRoutes = () => {
  const routes = getPublicRoutes();
  return useRoutes(routes);
};

export default PublicAppRoutes;
