import { USState } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getJobCodesByState } from '../../externalServices/V2/location';
import { getOwnerOfficerTitle } from '../../externalServices/V2/ownersOfficers';
import { useApplicationStore } from '../../pages/v2/application/store';
import { errorV2 } from '../../ui-core/Notification';
import { formatJobCodesToAntdOptions, formatToAntdOptions } from '../../utils/formatToAntdOptions';
import {
  OWNER_OFFICER_DETAILS_STORE_META_DATA,
  OWNERS_OFFICERS_ERROR,
  OWNERS_OFFICERS_TITLE_RESPONSE_KEY,
} from './constants';
import { IOwnerDetailsStore } from './interface';
import { createOwnerOfficerDetailsApiPayload, validateOwnerOfficerDetails } from './utils';

export const useOwnerOfficerDetailsStore = create<IOwnerDetailsStore>()(
  devtools(
    (set, get) => ({
      ownerOfficerDetailsList: [],
      ownerOfficerDetailsError: undefined,
      ownerOfficerDetailsLoading: false,
      ownerOfficerDetailsTabLoading: false,
      ownersOfficersTitleOptions: {},
      ownersOfficersClassCodes: {},

      updateOwnerOfficerDetailsStoreByKey: async (storeKey: keyof IOwnerDetailsStore, value: any) => {
        set(
          () => ({ [storeKey]: value }),
          false,
          OWNER_OFFICER_DETAILS_STORE_META_DATA.UPDATE_OWNER_OFFICER_STORE_BY_KEY,
        );
      },

      addOwnerOfficerDetails: (ownerOfficerDetails) => {
        const { ownerOfficerDetailsList } = get();

        set(
          {
            ownerOfficerDetailsList: [...ownerOfficerDetailsList, ownerOfficerDetails],
          },
          false,
          OWNER_OFFICER_DETAILS_STORE_META_DATA.ADD_OWNER_OFFICER_DETAILS,
        );
      },

      updateOwnerOfficerDetails: (ownerOfficerDetails, index) => {
        const { ownerOfficerDetailsList, ownerOfficerDetailsError } = get();
        const currentDetails = [...ownerOfficerDetailsList];
        const errors = ownerOfficerDetailsError ? [...ownerOfficerDetailsError] : [];

        currentDetails?.splice(index, 1, ownerOfficerDetails);
        errors?.splice(index, 1, { isError: false, errorMessage: '' });

        set(
          { ownerOfficerDetailsList: currentDetails, ownerOfficerDetailsError: errors },
          false,
          OWNER_OFFICER_DETAILS_STORE_META_DATA.UPDATE_OWNER_OFFICER_DETAILS,
        );
      },

      deleteOwnerOfficerDetails: (index: number) => {
        const { ownerOfficerDetailsList, ownerOfficerDetailsError } = get();
        const currentDetails = [...ownerOfficerDetailsList];
        const errors = ownerOfficerDetailsError ? [...ownerOfficerDetailsError] : [];

        currentDetails?.splice(index, 1);
        errors?.splice(index, 1);

        set(
          { ownerOfficerDetailsList: currentDetails, ownerOfficerDetailsError: errors },
          false,
          OWNER_OFFICER_DETAILS_STORE_META_DATA.DELETE_OWNER_OFFICER_DETAIL,
        );
      },

      fetchOwnerOfficerClassCodes: async (state: USState, loading = true) => {
        if (state?.trim()) {
          try {
            if (get().ownersOfficersClassCodes?.[state]?.data?.length > 0) {
              return get().ownersOfficersClassCodes?.[state]?.data;
            }

            set(
              {
                ownersOfficersClassCodes: {
                  ...get().ownersOfficersClassCodes,
                  [state]: { ...get().ownersOfficersClassCodes?.[state], loading },
                },
              },
              false,
              OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_OWNERS_OFFICERS_CLASS_CODE,
            );

            const jobCodesByStateResponse = await getJobCodesByState({ state });
            const { jobCodesByState } = jobCodesByStateResponse || {};
            const ownerOfficerClassCodes = formatJobCodesToAntdOptions(jobCodesByState);

            set(
              {
                ownersOfficersClassCodes: {
                  ...get().ownersOfficersClassCodes,
                  [state]: {
                    ...get().ownersOfficersClassCodes?.[state],
                    loading: false,
                    data: ownerOfficerClassCodes,
                  },
                },
              },
              false,
              OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_OWNERS_OFFICERS_TITLE_OPTIONS,
            );

            return ownerOfficerClassCodes;
          } catch (error: any) {
            errorV2(error?.[0]?.errorMessage || OWNERS_OFFICERS_ERROR.FAILED_TO_FETCH_STATE_CLASS_CODES);
            set(
              {
                ownersOfficersClassCodes: {
                  ...get().ownersOfficersClassCodes,
                  [state]: { ...get().ownersOfficersClassCodes?.[state], loading: false, data: [] },
                },
              },
              false,
              OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_OWNERS_OFFICERS_TITLE_OPTIONS,
            );

            return [];
          }
        } else {
          return [];
        }
      },

      autoFillOwnerOfficerDetails: () => {
        const { applicationData } = useApplicationStore.getState();
        const { companyStructure } = applicationData || {};
        const { ownershipDetails } = companyStructure || {};
        const { owners } = ownershipDetails || {};

        const ownerOfficerDetailsList = owners?.map((owner) => ({ ...owner })) || [];

        set({ ownerOfficerDetailsList }, false, OWNER_OFFICER_DETAILS_STORE_META_DATA.AUTO_FILL_OWNER_OFFICER_DETAILS);
      },

      fetchOwnerOfficerTitleOptions: async (state: USState, ownerOfficerModalLoading = true) => {
        if (state?.trim()) {
          const { applicationData } = useApplicationStore.getState();
          set(
            {
              ownersOfficersTitleOptions: {
                ...get().ownersOfficersTitleOptions,
                [state]: { ...get().ownersOfficersTitleOptions?.[state], loading: ownerOfficerModalLoading },
              },
            },
            false,
            OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_OWNERS_OFFICERS_TITLE_OPTIONS,
          );

          try {
            if (get().ownersOfficersTitleOptions?.[state]?.data?.length > 0) {
              set(
                {
                  ownersOfficersTitleOptions: {
                    ...get().ownersOfficersTitleOptions,
                    [state]: { ...get().ownersOfficersTitleOptions?.[state], loading: false },
                  },
                },
                false,
                OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_OWNERS_OFFICERS_TITLE_OPTIONS,
              );

              return get().ownersOfficersTitleOptions?.[state]?.data;
            }

            const ownersOfficersTitleRes = await getOwnerOfficerTitle({
              cfLegalEntityId: applicationData?.companyStructure?.legalEntityType!,
              state,
            });
            const ownersOfficersTitleOptionsRes = formatToAntdOptions({
              list: ownersOfficersTitleRes?.cfOwnerOfficerTitles || [],
              listLabelKey: OWNERS_OFFICERS_TITLE_RESPONSE_KEY.TITLE_LABEL,
              listValueKey: OWNERS_OFFICERS_TITLE_RESPONSE_KEY.TITLE_VALUE,
            });

            set(
              {
                ownersOfficersTitleOptions: {
                  ...get().ownersOfficersTitleOptions,
                  [state]: {
                    ...get().ownersOfficersTitleOptions?.[state],
                    loading: false,
                    data: ownersOfficersTitleOptionsRes,
                  },
                },
              },
              false,
              OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_OWNERS_OFFICERS_TITLE_OPTIONS,
            );

            return ownersOfficersTitleOptionsRes;
          } catch (error: any) {
            set(
              {
                ownersOfficersTitleOptions: {
                  ...get().ownersOfficersTitleOptions,
                  [state]: { ...get().ownersOfficersTitleOptions?.[state], loading: false, data: [] },
                },
              },
              false,
              OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_OWNERS_OFFICERS_TITLE_OPTIONS,
            );

            return [];
          }
        } else {
          return [];
        }
      },

      getOwnerOfficerDetailsApiPayload: () => {
        const { ownerOfficerDetailsList } = get();
        const { applicationData } = useApplicationStore.getState();

        const ownerOfficerDetailsPayload = createOwnerOfficerDetailsApiPayload(ownerOfficerDetailsList);
        return {
          ...applicationData,
          companyStructure: {
            ...applicationData?.companyStructure,
            ownershipDetails: {
              owners: ownerOfficerDetailsPayload,
            },
          },
        };
      },

      validateOwnerOfficerDetails: () => {
        const { ownerOfficerDetailsList } = get();
        const validatedOwnerOfficerDetails = validateOwnerOfficerDetails(ownerOfficerDetailsList || []);
        if (!validatedOwnerOfficerDetails.isValid) {
          set(
            { ownerOfficerDetailsError: validatedOwnerOfficerDetails.errors },
            false,
            OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_ERROR_OWNER_OFFICER_DETAILS,
          );
        } else {
          set(
            { ownerOfficerDetailsError: undefined },
            false,
            OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_ERROR_OWNER_OFFICER_DETAILS,
          );
        }
        return validatedOwnerOfficerDetails.isValid;
      },

      clearOwnerOfficerDetails: () => {
        set(
          {
            ownerOfficerDetailsList: [],
            ownerOfficerDetailsError: undefined,
            ownerOfficerDetailsLoading: false,
            ownerOfficerDetailsTabLoading: false,
            ownersOfficersTitleOptions: {},
            ownersOfficersClassCodes: {},
          },
          false,
          OWNER_OFFICER_DETAILS_STORE_META_DATA.CLEAR_OWNER_OFFICER_STORE,
        );
      },
    }),
    {
      anonymousActionType: 'OWNER_OFFICER_ANONYMOUS_ACTION',
      name: 'Owner officer details store',
    },
  ),
);
