import { CloseOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo } from 'react';

import { BUTTON_TYPE } from '../../../globalConstants';
import CFButton from '../cfButton/cfButton';
import { ICFDrawer } from './interface';
import { useCFDrawerStore } from './store';
import { CFDrawerStyled } from './styles';

const CFDrawer = ({ closeIcon, onClose, data, children, ...rest }: ICFDrawer) => {
  const { isVisible, clearCFDrawer } = useCFDrawerStore();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearCFDrawer(), []);

  const handleClose = useCallback(
    (event) => {
      onClose && onClose(event, data);
      clearCFDrawer();
    },
    [clearCFDrawer, data, onClose],
  );

  const getCloseIcon = useMemo((): React.ReactNode => {
    return (
      closeIcon ?? (
        <CFButton
          id='drawer_close'
          onClick={handleClose}
          buttonType={BUTTON_TYPE.ACTION}
          shape='circle'
          icon={<CloseOutlined />}
        />
      )
    );
  }, [closeIcon, handleClose]);

  return (
    <CFDrawerStyled open={isVisible} closeIcon={getCloseIcon} onClose={handleClose} {...rest}>
      {children}
    </CFDrawerStyled>
  );
};
export default CFDrawer;
