import { DiffOutlined, DollarOutlined, FileSearchOutlined } from '@ant-design/icons';
import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';

import { PAGE_ROUTES } from '../../../globalConstants';
import { useConfigurationStore } from '../../configuration/store';

export const getToolsMenuElements = () => {
  const { userType } = useConfigurationStore.getState();
  const agencyToolsMenuElements = [
    { icon: FileSearchOutlined, to: PAGE_ROUTES.APPETITE, label: 'Appetite Guide' },
    { icon: DiffOutlined, to: PAGE_ROUTES.REPORT_CENTER, label: 'Reports Center' },
  ];

  const agencyNetworkToolsMenuElements = [
    { icon: DiffOutlined, to: PAGE_ROUTES.REPORT_CENTER, label: 'Reports Center' },
    { icon: DollarOutlined, to: PAGE_ROUTES.PF_APPLICATIONS, label: 'Premium Financing' },
  ];
  return userType === AgentPortalUserType.AGENCY_NETWORK ? agencyNetworkToolsMenuElements : agencyToolsMenuElements;
};
