import { IPaymentRedirectStore } from './interface';

export const PAYMENT_REDIRECT_STORE_META_DATA = {
  UPDATE_PAYMENT_REDIRECT_STORE_BY_KEY: 'UPDATE_PAYMENT_REDIRECT_STORE_BY_KEY',
  CLEAR_PAYMENT_REDIRECT_STORE: 'CLEAR_PAYMENT_REDIRECT_STORE',
  STORE_NAME: 'PAYMENT_REDIRECT_STORE',
  ANONYMOUS_ACTION_NAME: 'PAYMENT_REDIRECT_ANONYMOUS_ACTION',
};

export const PAYMENT_REDIRECT_STORE_KEYS: { [key: string]: keyof IPaymentRedirectStore } = {
  PAYMENT_REDIRECT_DATA: 'paymentRedirectData',
  PAYMENT_REDIRECT_LOADING: 'paymentRedirectLoading',
  PAYMENT_REDIRECT_ERROR: 'paymentRedirectError',
};

export const PAYMENT_PUBLIC_REDIRECT_MESSAGES = {
  ERROR: 'Something went wrong',
};

export const PAYMENT_GATEWAY_PROFILE_ID_QUERY_PARAM_REPLACEMENT = 'pg_profileId';
