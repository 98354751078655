import { HELPER_SERVICE_ENDPOINTS } from '../../externalServices/helpers/ApiRoutes';
import { JWT_TOKEN, SESSION_STORAGE_KEYS } from '../../globalConstants';
import { getFromLocalStorage } from '../../utils/getFromLocalStorage';
import { getFromSessionStorage } from '../../utils/getFromSessionStorage';
import { uniqueId } from '../../utils/uniqueId';
import { IRequestCommonHeaders } from './interface';

export const REQUEST_COMMON_HEADERS = (): IRequestCommonHeaders => {
  return {
    authorization: `Bearer ${getFromLocalStorage(JWT_TOKEN.ACCESS_TOKEN)}`,
    'content-type': 'application/json',
    'x-request-id': `cfr-${uniqueId()}`,
    'x-agent-profile-id': `${getFromSessionStorage(SESSION_STORAGE_KEYS.PROFILE_ID)}`,
  };
};

export const API_ENDPOINTS_TO_LOG_SENTRY_ERROR = [HELPER_SERVICE_ENDPOINTS.GET_INDUSTRY_CLASS_CODES];
