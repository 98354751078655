import { IGetPaymentDetailsByCarrierTxnRefInput } from '@coverforce-platform/cf-common-api-model';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { redirectPaymentAPI } from '../../../externalServices/V2/payment';
import { PAYMENT_REDIRECT_STORE_META_DATA } from './constants';
import { IPaymentRedirectStore } from './interface';

export const usePaymentRedirectStore = create<IPaymentRedirectStore>()(
  devtools<IPaymentRedirectStore>((set) => ({
    paymentRedirectData: undefined,
    paymentRedirectLoading: true,
    paymentRedirectError: undefined,

    getPaymentRedirectData: async (param: IGetPaymentDetailsByCarrierTxnRefInput) => {
      const response = await redirectPaymentAPI(param);
      return response;
    },

    updatePaymentRedirectStoreByKey: async (storeKey: keyof IPaymentRedirectStore, value: any) => {
      set(() => ({ [storeKey]: value }), false, PAYMENT_REDIRECT_STORE_META_DATA.UPDATE_PAYMENT_REDIRECT_STORE_BY_KEY);
    },

    clearPaymentRedirectStore: () => {
      set(
        () => ({ paymentRedirectData: undefined, paymentRedirectLoading: true, paymentRedirectError: undefined }),
        false,
        PAYMENT_REDIRECT_STORE_META_DATA.CLEAR_PAYMENT_REDIRECT_STORE,
      );
    },
  })),
);
