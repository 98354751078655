import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { IScheduleDemoStore } from './interface';

export const useScheduleDemoStore = create<IScheduleDemoStore>()(
  devtools((set) => ({
    scheduleDemoLink: undefined,
    setScheduleDemoLink: (link: string) => set({ scheduleDemoLink: link }),
    clearScheduleDemoStore: () => set({ scheduleDemoLink: undefined }),
  })),
);
