import Paragraph from 'antd/lib/typography/Paragraph';
import styled from 'styled-components/macro';

export const CFParagraphStyled = styled(Paragraph)`
  margin-bottom: 0px !important;
  .ant-typography-expand {
    cursor: pointer;
    color: ${(p) => p.theme.colorScheme?.primary};
  }
  .ant-typography-expand:hover {
    color: ${(p) => p.theme.colorScheme?.primaryHover};
  }
`;
